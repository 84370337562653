export const User_root = "/";
export const free_trail = "/freeTrial";
export const join = "/join";
export const loginurl = "/login";
export const signupurl = "/additionalInformation";
export const free_output = "/freeOutput";
export const admin_setting = "/admin/setting";
export const admin_manageuser = "/admin/manage-user";
export const planner_order = "/planner/order";
export const planner_skuSelection = "/planner/order-details";
export const planner_contSelection = "/planner/container-details";
